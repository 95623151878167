module.exports = function TileOnClick() {
    
  var tilesc = $('.tile_switch-colors');
  var tilescr = $('.tile_switch-colors-rev');

  function ToggleDisplay(thistile) {
    if ($('*', thistile).is('.mobile-toggle')) {
      $('.mobile-toggle', thistile).toggleClass('mobile-show');
      thistile.toggleClass('js_hidden-el');
      thistile.toggleClass('js_hidden-el_clicked'); 
    };
    return true;
  };
  $(tilesc).add(tilescr).on( "click", function() {
    if ($(window).width() <= 768) {
      ToggleDisplay($(this));
    }  
  });
};
