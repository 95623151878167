module.exports = function IconsRecolor() {

  window.onload=function() {
    
    var iconscoll = $('.icons__svg');

    for (var index = 0; index < iconscoll.length; index++) {
      currentSvgRecolor(iconscoll[index], '#18bc9c');
    }

    function currentSvgRecolor(element, fill) {
      var svgDoc = element.contentDocument;
      fill = 'fill: ' + fill;

      if ($('*',svgDoc).is('#path3398')) { 
        var svgDocItem = $('#path3400', svgDoc);
        var svgDocItem2 = $("#path3398", svgDoc);

        svgDocItem.attr('style',  fill);
        svgDocItem2.attr('style', fill);

      } else if ($('*',svgDoc).is('#path3318')) {
        var svgDocItem = $('#path3318', svgDoc);
        var svgDocItem2 = $('#path3320', svgDoc);
        var svgDocItem3 = $('#path3322', svgDoc);

        svgDocItem.attr('style',  fill);
        svgDocItem2.attr('style', fill);
        svgDocItem3.attr('style', fill);

      } else if ($('*',svgDoc).is('#path3176')) {
        var svgDocItem = $('#path3176', svgDoc);
        var svgDocItem2 = $('#path3178', svgDoc);

        svgDocItem.attr('style',  fill);
        svgDocItem2.attr('style', fill);
      }
    }

    try {
      $('.icons__link').mouseover(function() {
        var currentsvg = $('.icons__svg', this);
        console.log(currentsvg);
        currentSvgRecolor(currentsvg, '#ffffff');
      })
      .mouseout(function() {
        var currentsvg = $('.icons__svg', this);
        console.log(currentsvg);
        currentSvgRecolor(currentsvg, '#18bc9c');
      });
    } catch (error) { console.log('icons__svg not found')}
    try {
      //currentSvgRecolor(iconscoll, 1, '#18bc9c');
    } catch (error) { console.log('icons__svg not found')}
    try {
      //currentSvgRecolor(iconscoll, 2, '#18bc9c');
    } catch (error) { console.log('icons__svg not found')}
    
    
    
    

  };
};  
