document.addEventListener('DOMContentLoaded', function() {
  console.log('everything OK');

  var ScrollToAnchor  = require('../lib/scrolltoanchor');
  var NavMenuActions  = require('../blocks.default/nav-menu');
  var IconsRecolor    = require('../blocks.default/icons');
  var TileOnClick     = require('../blocks.default/tile');

  ScrollToAnchor('800');
  NavMenuActions();
  IconsRecolor();
  TileOnClick();
  
});
