module.exports = function ScrollToAnchor(speed) {
  $('a[href*="#"]').on("click", function(event){
    var anchor = $(this).attr('href').substring(1);
    console.log(anchor);
    try {
      $('html, body').stop().animate({
        scrollTop: $('a[name*='+anchor+']').offset().top
    }, speed);
    event.preventDefault();
    } catch (error) {
      console.log('Anchor missing: ' + error.message);
    }
  });
};
